import React from "react";

const ApprovalStatus = (props) => {
	if (!props.status) {
		return null;
	}
	let styles = "";
	switch (props.status.toLowerCase()) {
		case "passed":
			styles = "bg-green-100  border-green-200 text-green-600";
			break;
		case "approved":
			styles = "bg-green-300  border-green-400 text-green-700";
			break;
		case "rejected":
			styles = "bg-red-100  border-red-200 text-red-600";
			break;
		case "needs approval":
			styles = "bg-yellow-100  border-yellow-200 text-yellow-600";
			break;
		case "dispatched":
			styles = "bg-blue-100  border-blue-200 text-blue-600";
			break;
		default:
			styles = "bg-blue-100  border-blue-200 text-blue-600";
			break;
	}

	return (
		<div
			className={
				"py-1 px-2 border text-center rounded-md w-full max-w-[10rem] " + styles
			}
		>
			{props.status}
		</div>
	);
};

export default ApprovalStatus;
