import React from "react";
import { CgPill } from "react-icons/cg";
import { textToRemove } from "../helpers";

const MedicinesList = (props) => {
  let medicinesText = "";

  props.medicines.forEach((medicine, index) => {
    if (medicine.AnswerGiven !== "NA") {
      let answerGivenText = medicine.AnswerGivenText;
      let endString =
        index !== props.medicines.length - 1 &&
        answerGivenText[answerGivenText.length - 1] !== ","
          ? ","
          : "";

      if (answerGivenText.includes(textToRemove)) {
        answerGivenText = answerGivenText.replace(textToRemove, "");
        answerGivenText = answerGivenText.replace(" ,", "");

        endString = " ";
      }

      medicinesText += answerGivenText + endString;
    }
  });

  medicinesText =
    medicinesText[medicinesText.length - 1] === ","
      ? medicinesText.slice(0, -1)
      : medicinesText;

  return (
    <div className="h-full min-h-[13rem] w-full max-w-[16rem] rounded border px-4 py-2 shadow-sm">
      <h2 className="mb-1 flex items-center justify-center gap-1 text-lg font-medium">
        <CgPill />
        Medicines
      </h2>
      <div className="divide-y divide-gray-200 p-1">
        <p className="text-sm">{medicinesText}</p>
      </div>
    </div>
  );
};

export default MedicinesList;
