import { useRef } from 'react';
import useImmutableCallback from './useImmutableCallback.js';
import useMountedState from './useMountedState.js';

export default function useLoadingState(func, options = {}) {
	let idRef = useRef(0);

    let [running, setRunning] = useMountedState(false);
    let [pending, setPending] = useMountedState(false);

    let callback = useImmutableCallback(async function (...args) {
    	let count = ++idRef.current;

    	let { delayMs = 200, minBusyMs = 500 } = options;

    	let minBusyPromise;
    	setTimeout(function () {
    		if (count === idRef.current) {
    			minBusyPromise = new Promise(function (resolve) {
    				setTimeout(resolve, minBusyMs);
    			});

    			setPending(true);
    		}
    	}, delayMs);

    	try {
    		setRunning(true);

    		let result = await func(...args);

    		return result;
    	} finally {
    		if (minBusyPromise) await minBusyPromise;
    		if (count === idRef.current) {
    			idRef.current = -1;
    			setRunning(false);
    			setPending(false);
    		}
    	}
    });

    return [callback, running, pending];
}
