import React from 'react'

const Checkbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
	const defaultRef = React.useRef();
	const resolvedRef = ref || defaultRef;

	React.useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);
	return (
		<>
			<input
				type="checkbox"
				className={
					"border-gray-200 rounded text-blue-600 focus:ring-blue-500 " +
					(indeterminate ? "text-gray-300" : "")
				}
				ref={resolvedRef}
				{...rest}
			/>
			{/* <input type="checkbox" ref={resolvedRef} {...rest} /> */}
		</>
	);
});

export default Checkbox;