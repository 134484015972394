import React from "react";

const FailedChecks = (props) => {
    //get all checks and list them out in Failed: Questionnaire, Identity, etc.
    var strFailedChecks = "Failed: ";

    if(props.questionnaire) {
        strFailedChecks += "Questionnaire, ";
    }

    if(!props.identity) {
        strFailedChecks += "Identity, ";
    }

    if(!props.gender) {
        strFailedChecks += "Gender, ";
    }

    if(props.fPurchaser) {
        strFailedChecks += "Frequent Purchaser, ";
    }

    strFailedChecks = strFailedChecks.substring(0, strFailedChecks.length - 2);

    return (
        <div>
            <h3
                className={
                    "text-lg font-medium whitespace-nowrap failed-checks "
                }
            >
                {strFailedChecks}
            </h3>
        </div>
    );
};

export default FailedChecks;
