import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";

export default function RadioGroupCards(props) {
  return (
    <div className="w-full  ">
      <div className="mx-auto w-full max-w-md">
        <RadioGroup value={props.selectedSMS} onChange={props.setSelectedSMS}>
          <div className="space-y-2">
            {props.options.map((sms, index) => (
              <RadioGroup.Option
                key={sms.name}
                value={index}
                title={sms.text}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300"
                      : ""
                  }
                  ${
                    checked
                      ? "bg-sky-900 bg-opacity-75 text-white shadow-md"
                      : "bg-white"
                  }
                    relative flex cursor-pointer rounded-lg border px-5 py-4  focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div
                        className={`mr-5 shrink-0 rounded-full text-white ${
                          checked ? "" : "	"
                        }`}
                      >
                        <CheckIcon className="h-6 w-6 " checked={checked} />
                      </div>
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`mb-2 font-medium ${
                              checked ? "text-white" : "text-gray-900"
                            }`}
                          >
                            {sms.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={` ${
                              checked
                                ? "text-sky-100"
                                : "line-clamp-2 text-gray-500	"
                            }`}
                          >
                            {sms.text}
                          </RadioGroup.Description>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}

function CheckIcon(props) {
  return (
    <div className="rounded-full border">
      <svg viewBox="0 0 24 24" fill="none" {...props}>
        <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
        {props.checked && (
          <path
            d="M7 13l3 3 7-7"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </svg>
    </div>
  );
}
