import React from "react";
import { textToRemove } from "../helpers";

const QuestionnaireAnswers = (props) => {
  // Check if the asnwerGivenText contains the string "I am taking (or have recently stopped taking) a medicine (not listed above) that I’d like to discuss with the pharmacist,"
  // If it does, then remove it.

  let answerGivenText = props.answerGivenText;

  if (answerGivenText.includes(textToRemove)) {
    answerGivenText = answerGivenText.replace(textToRemove, "");
  }

  if (answerGivenText === "NA") {
    return "Not Applicable";
  }

  if (answerGivenText.includes(", ,")) {
    answerGivenText = answerGivenText.replace(", ,", "");
  }

  answerGivenText =
    answerGivenText[answerGivenText.length - 1] === ","
      ? answerGivenText.slice(0, -1)
      : answerGivenText;

  return (
    <div>
      {props.questionRecordId == "a_1_b_2_name" && (
        <p className="mt-2 text-sm text-gray-500">
          <span className="font-medium">Name:</span>
        </p>
      )}
      {props.questionRecordId == "a_1_b_2_phone" && (
        <p className="text-sm text-gray-500">
          <span className="font-medium">Phone:</span>
        </p>
      )}
      {answerGivenText}
    </div>
  );
};

export default QuestionnaireAnswers;
