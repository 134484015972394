import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { baseURL } from "../../../helpers/fetchingURL";
import ApprovalContactSwitch from "./ApprovalContactSwitch";

const CustomerDetails = ({
  shipping,
  billing,
  email,
  responseReference,
  dob,
  contacted,
  orderData
}) => {
  const { getAccessTokenSilently } = useAuth0();

  async function handleDOBChange(e) {
    const accessToken = await getAccessTokenSilently();

    fetch(`${baseURL}/api/order/dob`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        responseReference,
        dob: e.target.value,
      }),
    });
  }

  return (
    <div className="flex flex-col gap-8">
      <div>
        <h2 className="mb-2 text-xl font-medium">Customer Details</h2>
        <div className="flex flex-col flex-wrap space-y-4 xl:flex-row xl:space-x-12 xl:space-y-0">
          {printDetails("Billing", billing, email)}
          {printDetails("Shipping", shipping)}
          <div>
            <h3 className="mb-2 font-medium">Date of birth:</h3>
            {/* Date picker */}
            <input
              type="date"
              className="rounded border px-2 py-1 text-sm"
              onChange={handleDOBChange}
              defaultValue={
                // Format date to yyyy-mm-dd
                dob ? dob.split("T")[0] : ""
              }
            />
          </div>
          {orderData.partner_purchase === 1 && (
            <div>
              <h3 className="mb-2 font-medium">Partner Details:</h3>
              <p className="mb-2 text-sm">
                <span className="font-medium">Name:</span> {orderData.partner_name}
              </p>
              <p className="text-sm">
                <span className="font-medium">Phone:</span> {orderData.partner_phone}
              </p>
            </div>
          )}

          {/* <div>
            <ApprovalContactSwitch
              responseReference={responseReference}
              checked={contacted}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;

function printDetails(title, data, email) {
	return (
		<div className="flex flex-row flex-wrap justify-between">
			<div className="flex flex-col gap-2">
				<h3 className="font-medium">{title}:</h3>
				<p className="text-sm">
					<span className="font-medium">Name:</span> {data.name}
				</p>
				<p className="text-sm font-medium">Address: </p>
				<p className="text-sm">{data.address1}</p>
				<p className="text-sm">{data.address2}</p>
				<p className="text-sm">
					<span className="font-medium">City:</span> {data.city}
				</p>
				<p className="text-sm">
					<span className="font-medium">Postcode:</span> {data.postcode}
				</p>
				<p className="text-sm">
					<span className="font-medium">Phone:</span> {data.phone}
				</p>
				{email && (
					<p className="text-sm">
						<span className="font-medium">Email:</span>{" "}
						<a href={`mailto:${email}`} className="hover:underline">
							{email}
						</a>
					</p>
				)}
			</div>
		</div>
	);
}
