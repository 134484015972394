import React, { useCallback, useEffect, useState } from "react";
import { baseURL } from "../../helpers/fetchingURL";
import { useSearchParams } from "react-router-dom";
import { BiXCircle } from "react-icons/bi";

const Search = ({ searchTerm }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [inputValue, setInputValue] = useState(searchTerm);

	// Debounce function
	function debounce(func, delay) {
			let timer;
			return function (...args) {
					clearTimeout(timer);
					timer = setTimeout(() => {
							func.apply(this, args);
					}, delay);
			};
	}

	// Handle change event
	const handleChange = (value) => {
			const currentParams = Object.fromEntries([...searchParams]);
			currentParams.search = value;
			if (value === "") {
					delete currentParams.search;
			}
			currentParams.page = 1;
			const urlParams = new URLSearchParams(currentParams).toString();
			setSearchParams(urlParams);
	};

	// Debounced version of handleChange
	const debouncedChangeHandler = useCallback(
			debounce(handleChange, 400),
			[searchParams]
	);

	// Update local state and debounced URL params on input change
	const handleInputChange = (e) => {
			setInputValue(e.target.value);
			debouncedChangeHandler(e.target.value);
	};

	// Clear search function
	function clearSearch() {
			setInputValue('');
			handleChange('');
	}

	return (
		<div className="relative w-full md:w-auto">
			<input
				type="text"
				id="table-search"
				name="table-search"
				className="py-2 px-3 pl-11 block w-full border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
				placeholder="Search columns"
				onChange={handleInputChange}
				value={inputValue}
			/>
			<div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
				<svg
					className="h-4 w-4 text-gray-400"
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					fill="currentColor"
					viewBox="0 0 16 16"
				>
					<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
				</svg>
			</div>
			{searchTerm.length > 0 && (
				<div
					onClick={clearSearch}
					className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer p-1"
				>
					<BiXCircle size="20px" color="rgb(156, 163, 175)"/>
				</div>
			)}
		</div>
	);
};

export default Search;
