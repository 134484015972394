import { useAuth0 } from "@auth0/auth0-react";
import { BiLogOut } from "react-icons/bi";

const LogoutButton = () => {
	const { logout } = useAuth0();

	return (
		<button
			onClick={() =>
				logout({ logoutParams: { returnTo: window.location.origin } })
			}
			className="flex items-center space-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 w-full"
		>
			<BiLogOut size="20px" />
			<span>Log Out</span>
		</button>
	);
};

export default LogoutButton;
