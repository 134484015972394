import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { RefreshContextProvider } from './ContextProvider';
import { baseURL } from './helpers/fetchingURL';

const root = ReactDOM.createRoot(document.getElementById('root'));

fetch(`${baseURL}/env`)
  .then((response) => response.json())
  .then((data) => {
    // Save the environment variables to the global window object
    window.env = data;


    // Render the React app after receiving the environment variables
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <Auth0Provider
            domain={"cialis-dashboard.uk.auth0.com"}
            clientId={"PpxSjRlW00THWH074hGvjWgMDW679mAs"}
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: "cialisdashboard.precisionpharmacy.co.uk",

            }}

          >
            <RefreshContextProvider>
              <App />
            </RefreshContextProvider>
          </Auth0Provider>
        </BrowserRouter>
      </React.StrictMode>
    );
  })
  .catch((error) => {
    console.error("Error fetching environment variables", error);
  });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
