import React, { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useClickOutside(ref, action) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [action, ref]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function ClickOutside(props) {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, props.action);

  return <div ref={wrapperRef} className="">{props.children}</div>;
}