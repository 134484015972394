export function displayPackQuantity(productName, productQuantity) {
  if (productName.includes("|")) {
    let packQuantity = productQuantity.split("|");
    let productNames = productName.split("|");

    productNames[0] = productNames[0].includes("8") ? "CT8" : "CT4";
    productNames[1] = productNames[1].includes("8") ? "CT8" : "CT4";

    if (productName.includes("12") && productName.includes("8")) {
      productNames[0] = "CT8";
      productNames[1] = "CT4";
    }

    if (productName.includes("12") && productName.includes("4")) {
      const is8PackFirst = productName.indexOf("12") < productName.indexOf("4");
      productNames[0] = is8PackFirst ? "CT8" : "CT4";
      productNames[1] = is8PackFirst ? "CT4" : "CT8";
    }

    let labels = [
      { label: productNames[0], quantity: String(packQuantity[0]).trim() },
      { label: productNames[1], quantity: String(packQuantity[1]).trim() },
    ];

    labels.sort((a, b) => a.label.localeCompare(b.label));

    return (
      <>
        {labels.map((obj, index) => {
          return (
            <p className="text-ellipsis" key={index}>
              {obj.label}x<strong>{obj.quantity}</strong>
            </p>
          );
        })}
      </>
    );
  } else {
    let packName = productName.includes("4") ? "CT4" : "CT8";
    packName = productName.includes("24") ? "CT8" : packName;

    if (productName.includes("12")) {
      return (
        <>
          <p className="text-ellipsis">
            CT4x<strong>1</strong>
          </p>
          <p className="text-ellipsis">
            CT8x<strong>1</strong>
          </p>
        </>
      );
    }

    return (
      <>
        <p className="text-ellipsis">
          {packName}x<strong>{productQuantity}</strong>
        </p>
      </>
    );
  }
}
