import { createContext, useState } from "react";

// Create the context
const RefreshContext = createContext();

// Create a provider component
const RefreshContextProvider = ({ children }) => {
  // Toggle this boolean to trigger a useffect re-fetch, re-fetches when inverting the variable.
  const [refreshData, setRefreshData] = useState(false);
  const [userRoles, setUserRoles] = useState([]);

  return (
    <RefreshContext.Provider value={{ refreshData, setRefreshData, userRoles, setUserRoles }}>
      {children}
    </RefreshContext.Provider>
  );
};

export { RefreshContext, RefreshContextProvider };