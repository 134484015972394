import React, { useContext } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApprovalStatus from "../../components/ApprovalStatus/ApprovalStatus";
import FailedChecks from "../../components/FailedChecks/FailedChecks";
import { useState, useEffect } from "react";
import QuestionnaireQuestions from "./components/QuestionnaireQuestions";
import QuestionnaireAnswers from "./components/QuestionnaireAnswers";
import MedicinesList from "./components/MedicinesList";
import CustomerDetails from "./components/CustomerDetails";
import Notes from "./components/Notes";
import { baseURL } from "../../helpers/fetchingURL";
import { RefreshContext } from "../../ContextProvider";
import CallbackSMS from "./components/CallbackSMS";
import { useAuth0 } from "@auth0/auth0-react";
import { textToRemove } from "./helpers";
import { BiLinkExternal } from "react-icons/bi";

const Order = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  // Access environment variables from window.env object
  const shopAdminURL = window.env.REACT_APP_SHOP_ADMIN_URL;

  const location = useLocation();
  let { orderId } = useParams(); // get the order id from the url

  const { data } = location.state;
  const [questionaireData, setQuestionaireData] = useState(null);
  const [error, setError] = useState(null);
  // Initially use data passed from the table to avoid another fetch.
  const [orderData, setOrderData] = useState(data);
  const [shippingInfo, setShippingInfo] = useState(groupShipping);
  const [billingInfo, setBillingInfo] = useState(groupBilling);
  const navigate = useNavigate();
  const { refreshData } = useContext(RefreshContext);
  const [medicine, setMedicine] = useState();

  // on load put shipping data and billing data into state for tidiness
  function groupBilling() {
    return {
      name: orderData.billing_name,
      address1: orderData.billing_address1,
      address2: orderData.billing_address2,
      city: orderData.billing_city,
      postcode: orderData.billing_zip,
      country: orderData.billing_country,
      phone: orderData.billing_phone,
    };
  }

  function groupShipping() {
    return {
      name: orderData.shipping_name,
      address1: orderData.shipping_address1,
      address2: orderData.shipping_address2,
      city: orderData.shipping_city,
      postcode: orderData.shipping_zip,
      country: orderData.shipping_country,
      phone: orderData.shipping_phone,
    };
  }

  useEffect(() => {
    const abortController = new AbortController();

    const fetchQuestionnaire = async () => {
      const accessToken = await getAccessTokenSilently();

      let requestOptions = {
        method: "GET",
        signal: abortController.signal, // Pass the signal to the fetch options
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const id = data.ResponseReference;

      const fetchUrl = `${baseURL}/api/getQuestionnaire/${id}`;

      const response = await fetch(fetchUrl, requestOptions);

      const responseData = await response.json();

      // find QuestionRecordId == "10" || "10_other" responseData has a  save it to state setMedicineList
      let medicineDbRecord = responseData.filter(
        (item) =>
          (item.AnswerGivenText !== undefined &&
            item.QuestionRecordId === "10" &&
            item.AnswerGiven !== "NA") ||
          (item.QuestionRecordId === "a_10_other" &&
            item.AnswerGivenText !== "NA"),
      );

      console.log("qn medicineDbRecord", medicineDbRecord);

      setMedicine(medicineDbRecord);
      console.log("qn responseData", responseData);
      setQuestionaireData(responseData);
    };

    fetchQuestionnaire();

    console.log("orderData", orderData);
    props.setSelectedRows([orderData]);

    return () => {
      // cleanup fetch
      abortController.abort();
    };
  }, []);

  //TODO: Make this run only when the refreshData state changes, currently runs on load
  useEffect(() => {
    const abortController = new AbortController();

    const fetchOrder = async () => {
      const accessToken = await getAccessTokenSilently();

      let requestOptions = {
        method: "GET",
        signal: abortController.signal, // Pass the signal to the fetch options
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const id = data.ResponseReference;

      const fetchUrl = `${baseURL}/api/order/${id}`;

      const response = await fetch(fetchUrl, requestOptions);

      const responseData = await response.json();

      console.log("responseData", responseData);

      setOrderData(responseData[0]);
      props.setSelectedRows([responseData[0]]);
    };
    console.log("refreshData", refreshData);
    fetchOrder();

    return () => {
      // cleanup fetch
      abortController.abort();
    };
  }, [refreshData]);

	useEffect(() => {
		console.log("orderData", orderData);
	}, [orderData])
	

  return (
    <div className="flex h-full flex-col p-2 md:p-8">
      <button
        className="mb-6 flex w-fit items-center gap-2 text-base font-medium text-blue-500 hover:text-blue-700"
        onClick={() => {
          navigate(-1);
        }}
      >
        <BsArrowLeftShort size={24} /> Back to Orders
      </button>

      <div className="mb-4 flex items-center">
        <div className="flex w-full items-center space-x-4">
          <a
            href={`${shopAdminURL}/${orderData.ShopifyId}`}
            target="_blank"
            rel="noreferrer"
            className="flex items-center space-x-2 hover:underline"
            title="View order in Shopify"
          >
            <h1 className="whitespace-nowrap text-lg font-medium md:text-2xl">
              Order #{orderData.order_number} - {orderData.customer_first_name}{" "}
              {orderData.customer_last_name}
            </h1>
            <BiLinkExternal size={20} />
          </a>

          <ApprovalStatus status={orderData.pharmacy_status} />
          {orderData.partner_purchase == "1" && (
            <p className="text-lg font-medium">Partner Purchase</p>
          )}
          {orderData.pharmacy_status == "Needs Approval" &&
            orderData.partner_purchase == "1" && (
              <p className="text-lg font-medium">•</p>
            )}

          {orderData.pharmacy_status == "Needs Approval" && (
            <FailedChecks
              questionnaire={orderData.QuestionnaireFailed}
              identity={orderData.id_check_pass}
              gender={orderData.gender_check_male}
              fPurchaser={orderData.FrequentPurchaseCheck}
            />
          )}
        </div>
        <CallbackSMS
          phone={orderData.shipping_phone}
          name={orderData.shipping_name}
          orderNum={orderId}
          responseReference={orderData.ResponseReference}
        />
      </div>
      <div className="flex w-full flex-col gap-4 md:grid md:grid-cols-[3fr_350px]">
        <div className="flex h-fit w-full flex-col gap-12 rounded border bg-white p-4 drop-shadow-sm">
          <div className="flex justify-between">
            <CustomerDetails
              billing={billingInfo}
              shipping={shippingInfo}
              email={orderData.contact_email}
              responseReference={orderData.ResponseReference}
              dob={orderData.CustomerDateOfBirth}
              orderData={orderData}
            />
            {/* if questionaireData has a QuestionRecordId == "10_other" display medicines list */}
            {medicine !== undefined && medicine[0] !== undefined && (
              <MedicinesList medicines={medicine} />
            )}
          </div>
          <div className="">
            <h2 className="mb-4 text-xl font-medium">
              Questionnaire Details -{" "}
              <span className="text-sm">{orderData.ResponseReference}</span>
            </h2>
            <div className="space-y-3">
              {questionaireData &&
                questionaireData.map((qnData, index) => (
                  <div
                    key={qnData.AnswerRecordId}
                  >
                    <QuestionnaireQuestions
                      index={index}
                      questionRecordId={qnData.QuestionRecordId}
                    />
                    <QuestionnaireAnswers
                      answerGivenText={qnData.AnswerGivenText}
                      index={index}
                      questionRecordId={qnData.QuestionRecordId}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>

        <Notes responseReference={data.ResponseReference} />
      </div>
    </div>
  );
};

export default Order;
