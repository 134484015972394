import React from "react";

const Button = (props) => {
	// Default class
	let className =
		" py-2 px-3 flex justify-center items-center  rounded-md border font-medium shadow-sm align-middle transition-all text-sm ";

	// Add color
	switch (props.color) {
		case "green":
			className +=
				" bg-[#1bb45c] text-white hover:bg-[#15994d] focus:outline-none focus:ring-2 focus:ring-offset-2 border-green-600 focus:ring-emerald-600 focus:ring-offset-white disabled:bg-[#b5e9ca] disabled:border-[#b5e9ca] disabled:shadow-none disabled:cursor-not-allowed";
			break;
		case "red":
			className +=
				" border-rose-700 bg-rose-600 text-white shadow- hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-rose-600 disabled:bg-rose-200 disabled:border-rose-200 disabled:shadow-none disabled:cursor-not-allowed";
			break;
		case "blue":
			className +=
				" border-blue-600 bg-blue-600 text-white shadow- hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 disabled:bg-blue-200 disabled:border-blue-200 disabled:shadow-none disabled:cursor-not-allowed";
			break;
		default:
			className +=
				" bg-white text-gray-700 hover:bg-gray-50 focus:ring-blue-600 focus:ring-offset-white focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-50 disabled:shadow-none cursor-pointer disabled:cursor-not-allowed";
			break;
	}

	// Add space to children i.e icons
	if (props.children.length > 1) {
		className += " space-x-2";
	}

	return (
		<button
			className={className + " " + props.className}
			onClick={props.onClick}
			disabled={props.disabled}
			type={props.type}
		>
			{props.children}
		</button>
	);
};

export default Button;
