import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import LogoutButton from "./LogoutButton";
import ClickOutside from "../Utility/ClickOutside";

const Profile = () => {
	const { user, isAuthenticated, isLoading } = useAuth0();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="relative">
			<ClickOutside action={() => setIsOpen(false)}>
				<div
					className="flex items-center space-x-2 cursor-pointer hover:bg-neutral-100 rounded-md p-2 "
					onClick={() => setIsOpen((prevState) => !prevState)}
				>
					<img
						src={user.picture}
						alt={user.name}
						className="w-8 h-8 rounded-full "
					/>
					<p className="hidden sm:block">{user.nickname}</p>
				</div>
				{isOpen && (
					<div className="p-2 border z-10 w-48 rounded-lg flex flex-row flex-wrap gap-1 sm:gap-0 sm:flex-col shadow-md absolute bg-white top-[calc(100%+2px)]">
						<LogoutButton />
					</div>
				)}
			</ClickOutside>
		</div>
	);
};

export default Profile;
