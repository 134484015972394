import React, { useContext, useState } from "react";
import Modal from "../Reusables/Modal";
import Button from "../Reusables/Button";
import { baseURL } from "../../helpers/fetchingURL";
import { RefreshContext } from "../../ContextProvider";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "../Utility/Spinner";

const ApprovalModal = ({ data, isOpen, setIsOpen, toStatus }) => {
	const { user, getAccessTokenSilently } = useAuth0();
	const { setRefreshData } = useContext(RefreshContext);
	const [isSubmitting, setIsSubmitting] = useState(false);
	

	let verb = "";
	let color = "";
	switch (toStatus) {
		case "Approved":
			verb = "approve";
			color = "green";
			break;
		case "Rejected":
			verb = "reject";
			color = "red";
			break;
		case "Dispatched":
			verb = "dispatch";
			color = "blue";
			break;
		default:
			break;
	}

	async function handleSubmit(e) {
		setIsSubmitting(true);
		e.preventDefault();
		try {
			let responseReference = data.map((item) => item.ResponseReference);

			// Get shopify id from json data
			let shopifyId = data.map((item) => item.ShopifyId);

			const accessToken = await getAccessTokenSilently();
			// Dispatch orders to Mintsoft if status is Dispatched
			if (toStatus === "Dispatched") {
				let orderData = data.map((item) => {
					// Check if item.product_id has a | in it, if so split it into an array, else turn into array. Assume the other fields will also have a | in them.
					if (item.product_id.includes(" | ")) {
						item.product_id = item.product_id.split(" | ");
						item.product_quantity = item.product_quantity.split(" | ");
						item.product_price = item.product_price.split(" | ");
					} else {
						item.product_id = [item.product_id];
						item.product_quantity = [item.product_quantity];
						item.product_price = [item.product_price];
					}

					return {
						OrderItems: item.product_id.map((id, index) => {
							return {
								SKU: id,
								Quantity: item.product_quantity[index],
								UnitPrice: item.product_price[index],
							};
						}),

						ShopifyId: item.ShopifyId,
						ProductId: item.product_id,
						// Mintsoft specific data
						UnitPrice: item.product_price,
						Quantity: item.product_quantity,
						OrderNumber: item.ResponseReference,
						ExternalOrderReference: item.order_number,
						FirstName: item.shipping_first_name,
						LastName: item.shipping_last_name,
						Address1: item.shipping_address1,
						Address2: item.shipping_address2,
						Address3: "",
						Town: item.shipping_city,
						PostCode: item.shipping_zip,
						Country: item.shipping_country_code,
						Phone: item.shipping_phone,
						Mobile: item.shipping_phone,
						Email: item.contact_email,
						ShippingMethod: item.ShippingMethod,
						created_at: item.created_at,
					};
				});

				console.log("mintsoft order data", orderData);
				const response = await fetch(`${baseURL}/api/order/`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify(orderData),
				});

				const mintsoftResponse = await response.json();

				console.log("mintsoft response", mintsoftResponse);
				if (mintsoftResponse[0].Success === false) {
					console.log("mintsoft error", mintsoftResponse);
					throw new Error("Mintsoft error");
				}
			}

			// Update order status in database
			const response = await fetch(`${baseURL}/api/order/status`, {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify({
					name: user.nickname,
					status: toStatus,
					responseReference,
					shopifyId,
				}),
			});

			const shopifyResponse = await response.json();
			console.log("shopifyResponse", shopifyResponse);

			setRefreshData((prevState) => !prevState);
			setIsSubmitting(false);
			setIsOpen(false);
		} catch (error) {
			console.log(error);
			setRefreshData((prevState) => !prevState);
			setIsSubmitting(false);
			setIsOpen(false);
		}
	}

	return (
		<Modal isOpen={isOpen} setIsOpen={!isSubmitting ? setIsOpen : () => {}}>
			<form
				className="w-full rounded bg-white p-4 shadow"
				onSubmit={handleSubmit}
			>
				<h1 className="text-lg">
					Are you sure you want to {verb}{" "}
					<span className="font-medium underline underline-offset-2">
						{data.length}
					</span>{" "}
					{data.length > 1 ? "orders" : "order"}?
				</h1>
				<div className="mb-6 mt-3">
					<p className="text-sm text-gray-500">
						{data.length > 1 ? "Orders" : "Order"}:
					</p>
					<div className="grid grid-cols-6">
						{data.map((item) => (
							<p className="text-sm" key={item.order_number}>
								{item.order_number}
							</p>
						))}
					</div>
				</div>
				<div className="grid grid-cols-2 space-x-2">
					<Button color={color} className="capitalize" disabled={isSubmitting}>
						<Spinner show={isSubmitting} className={"h-4"} />
						<span>{verb}</span>
					</Button>
					<Button disabled={isSubmitting} onClick={() => setIsOpen(false)}>Cancel</Button>
				</div>
			</form>
		</Modal>
	);
};

export default ApprovalModal;
