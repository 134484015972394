import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useTable, useFilters } from "react-table";

function Filter({ pharmaStatus, setPharmaStatus, setCurrentPage }) {
	// const { filterValue, setFilter } = column;
	const [searchParams, setSearchParams] = useSearchParams();


	const options = useMemo(() => {
		return ["Passed", "Partner Passed", "Needs Approval", "Approved",  "Rejected", "Dispatched"];
	}, []);

	function handleChange(e) {
		const currentParams = Object.fromEntries([...searchParams]);
		// Reflect the state change in the URL query params
		currentParams.status = e.target.value;
		// if status is set to all, remove the status param from the URL
		if (e.target.value === "") {
			delete currentParams.status;
		}
		currentParams.page = 1;
		const urlParams = new URLSearchParams(currentParams).toString();
		setSearchParams(urlParams);
	}

	return (
		<div className="flex items-center gap-1">
			<select
				className={"flex w-full rounded-md border-gray-200 px-3 py-2 pr-9 text-sm font-medium capitalize shadow-sm focus:border-blue-500 focus:ring-blue-500 md:w-fit" + (pharmaStatus === "" ? "text-neutral-600" : "text-neutral-900")}
				id="approvalFilter"
				value={pharmaStatus}
				onChange={(e) => handleChange(e)}
			>
				<option value="">Select Approval Status</option>
				{options.map((option) => (
					<option key={option} value={option} className="capitalize text-neutral-900">
						{option}
					</option>
				))}
			</select>
		</div>
	);
}

export default Filter;
