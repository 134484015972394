import React, { useEffect, useState } from "react";
import Modal from "../Reusables/Modal";
import Datepicker from "../Reusables/Datepicker";
import { BiX } from "react-icons/bi";
import { baseURL } from "../../helpers/fetchingURL";
import { useAuth0 } from "@auth0/auth0-react";

const ReportModal = ({ isOpen, setIsOpen }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  let fromDateLabel = new Date(fromDate);
  let toDateLabel = new Date(toDate);

  // Set back to previous day
  fromDateLabel.setDate(fromDateLabel.getDate() - 1);

  switch (fromDateLabel.getDay()) {
    case 0:
    case 6:
      fromDateLabel.setHours(10, 30);
      break;
    default:
      fromDateLabel.setHours(15, 30);
      break;
  }

  switch (toDateLabel.getDay()) {
    case 0:
    case 6:
      toDateLabel.setHours(10, 29, 59);
      break;
    default:
      toDateLabel.setHours(15, 29, 59);
      break;
  }

  const [reportData, setReportData] = useState({
    questionnaireFailed: 0,
    idCheck: 0,
    gender: 0,
    freqPurchaser: 0,
    total: 0,
  });
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // Get todays date suitable for input element dd-mm-yyyy
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;
    setToDate(todayDate);
    setFromDate(todayDate);
  }, []);

  useEffect(() => {
    const getReport = async () => {
      const accessToken = await getAccessTokenSilently();

      // Get report from database
      const response = await fetch(
        `${baseURL}/api/report?fromDate=${fromDate}&toDate=${toDate}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      const data = await response.json();
      console.log(data[0]);
      setReportData(data[0]);
    };
    if (fromDate && toDate) {
      getReport();
    }
  }, [toDate, fromDate]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="w-full rounded bg-white shadow">
        <div className="flex items-center justify-between border-b px-6 py-3 text-lg font-medium">
          <h1>Report</h1>
          <div
            className="rounded-full p-2 hover:cursor-pointer hover:bg-gray-50"
            onClick={() => setIsOpen(false)}
          >
            <BiX size="20px" />
          </div>
        </div>
        <div className="flex flex-col px-6 py-6">
          <div className="flex flex-col">
            <label className="mb-2 text-sm font-medium leading-none">
              Date
            </label>
            <div className="flex items-center">
              <Datepicker
                title={"From"}
                placeholder="Select date start"
                value={fromDate}
                setValue={setFromDate}
              />
              <span className="mx-4 text-gray-500">to</span>
              <Datepicker
                title={"To"}
                placeholder="Select date end"
                value={toDate}
                setValue={setToDate}
              />
            </div>
          </div>
          <div className="mt-4 flex justify-between py-3 text-base font-medium">
            <p>
              {fromDateLabel.toLocaleString("en-UK", {
                weekday: "short",
                hour: "2-digit",
                minute: "2-digit",
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })}
            </p>
            <p>-</p>
            <p>
              {toDateLabel.toLocaleString("en-UK", {
                weekday: "short",
                hour: "2-digit",
                minute: "2-digit",
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })}
            </p>
          </div>

          <div className="relative flex flex-col rounded border border-gray-300 bg-gray-50 px-4 py-3 text-sm">
            <div className="grid w-full grid-cols-2 gap-x-2 gap-y-1">
              <p className="font-medium">All Orders:</p>{" "}
              <p className="pr-2 text-right">{reportData.total}</p>
            </div>
            <div className="mt-2 grid w-full grid-cols-2 gap-x-2 gap-y-1">
              <p className="font-medium">Partner Purchases:</p>{" "}
              <p className="pr-2 text-right">
                {reportData["Partner Purchases"]
                  ? reportData["Partner Purchases"]
                  : 0}
              </p>
            </div>

            <p className="mt-2 font-medium">Order Activity</p>
            <div className="grid w-full grid-cols-2 gap-x-2 gap-y-1 rounded border border-gray-200 bg-gray-100 px-2 py-1">
              <p className="">Not Processed:</p>{" "}
              <p className="text-right">
                {reportData["Not Processed"] ? reportData["Not Processed"] : 0}
              </p>
              <p className="">Pharmacist Approved:</p>{" "}
              <p className="text-right">
                {reportData["Pharmacist Approved"]
                  ? reportData["Pharmacist Approved"]
                  : 0}
              </p>
              <p className="">Pharmacist Rejected:</p>{" "}
              <p className="text-right">
                {reportData["Pharmacist Rejected"]
                  ? reportData["Pharmacist Rejected"]
                  : 0}
              </p>
            </div>
            <div className="mt-3 grid w-full grid-cols-2 gap-x-2 gap-y-1">
              <p className="font-medium">Needs Approval Orders:</p>{" "}
              <p className="pr-2 text-right">
                {reportData.NeedsApprovals ? reportData.NeedsApprovals : 0}
              </p>
            </div>
            <div className="grid w-full grid-cols-2 gap-x-2 gap-y-1 rounded border border-gray-200 bg-gray-100 px-2 py-1">
              <p className="">Questionnaire:</p>{" "}
              <p className="text-right">
                {reportData.questionnaireFailed
                  ? reportData.questionnaireFailed
                  : 0}
              </p>
              <p className="">Identity:</p>{" "}
              <p className="text-right">
                {reportData.idCheck ? reportData.idCheck : 0}
              </p>
              <p className="">Gender:</p>{" "}
              <p className="text-right">
                {reportData.gender ? reportData.gender : 0}
              </p>
              <p className="">Frequent Purchaser:</p>{" "}
              <p className="text-right">
                {reportData.freqPurchaser ? reportData.freqPurchaser : 0}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportModal;
