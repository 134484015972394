import React, { useEffect, useState } from "react";
import PerPageSelect from "./PerPageSelect";
import Button from "../Reusables/Button";
import {
	BsChevronDoubleLeft,
	BsChevronDoubleRight,
	BsChevronLeft,
	BsChevronRight,
} from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";

const Pagination = ({
	gotoPage,
	previousPage,
	nextPage,
	canPreviousPage,
	canNextPage,
	pageCount,
	pageOptions,
	pageIndex,
	limit,
	setLimit,
	totalPages,
	currentPage,
	setCurrentPage,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	// Navigation arrows enable/disable state
	const [canGoBack, setCanGoBack] = useState(false);
	const [canGoNext, setCanGoNext] = useState(true);

	// Disable previous and next buttons in the first and last page
	// respectively
	useEffect(() => {
		function handleNavigation() {
			if (totalPages === 0) {
				setCanGoNext(false);
				setCanGoBack(false);
				return;
			}
			if (totalPages === currentPage) {
				setCanGoNext(false);
			} else {
				setCanGoNext(true);
			}
			if (currentPage === 1) {
				setCanGoBack(false);
			} else {
				setCanGoBack(true);
			}
		}
		handleNavigation();
	}, [totalPages, currentPage]);

	/*
	 *	Change query params on navigation
	 */
	function handlePageChange(page) {
		const currentParams = Object.fromEntries([...searchParams]);
		// Update the URL query params let Table.jsx handle the state change
		currentParams.page = page;
		const urlParams = new URLSearchParams(currentParams).toString();
		setSearchParams(urlParams);
	}

	return (
		<div className="flex flex-col items-center gap-2 md:flex-row lg:gap-8">
			<div className="hidden gap-4 md:flex">
				<PerPageSelect
					setLimit={setLimit}
					pageSize={limit}
					setCurrentPage={setCurrentPage}
				/>
			</div>

			<div className="ml-auto flex w-full flex-col gap-2 md:w-auto md:flex-row">
				<div className="flex justify-between">
					<div className="mr-2 flex items-center gap-2 whitespace-nowrap text-sm">
						<span className="hidden md:block">Page </span>
						<input
							className="block w-20 rounded-md border-gray-200 py-2 pl-3 pr-2 text-sm font-semibold focus:border-blue-500 focus:ring-blue-500"
							type="number"
							value={currentPage}
							onChange={(e) => {
								const page = e.target.value > 0 ? Number(e.target.value) : 1;
								// Append query params to url
								if (page <= totalPages) handlePageChange(page);
							}}
						/>
						<span>
							of <span className="font-semibold">{totalPages}</span>
						</span>
					</div>
					<div className="block md:hidden">
						<PerPageSelect
							setPageSize={setLimit}
							pageSize={limit}
							setCurrentPage={handlePageChange}
						/>
					</div>
				</div>
				<div className="flex w-full gap-2">
					<Button onClick={() => handlePageChange(1)} disabled={!canGoBack}>
						<BsChevronDoubleLeft size={12} />
					</Button>
					<Button
						onClick={() => {
							// Append query params to url
							// handlePageChange(pageIndex);

							handlePageChange(currentPage - 1);
						}}
						disabled={!canGoBack}
						className="w-full md:w-fit"
					>
						<BsChevronLeft size={12} />
						<span>Prev</span>
					</Button>
					<Button
						type="button"
						onClick={() => {
							// Append query params to url
							// handlePageChange(pageIndex + 2);

							handlePageChange(currentPage + 1);
						}}
						disabled={!canGoNext}
						className="w-full md:w-fit"
					>
						<span>Next</span>
						<BsChevronRight size={12} />
					</Button>
					<Button onClick={() => handlePageChange(totalPages)} disabled={!canGoNext}>
						<BsChevronDoubleRight size={12} />
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
