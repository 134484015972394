import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const PerPageSelect = ({ pageSize, setLimit, setCurrentPage }) => {
	const [searchParams, setSearchParams] = useSearchParams();


	function handleChange(e) {
		const currentParams = Object.fromEntries([...searchParams]);
		// Update the URL query params let Table.jsx handle the state change
		currentParams.limit = e.target.value;
		currentParams.page = 1;
		const urlParams = new URLSearchParams(currentParams).toString();
		setSearchParams(urlParams);
	}

	return (
		<div className="flex items-center gap-2">
			<label htmlFor="perPage" className="whitespace-nowrap text-sm">
				Per page:
			</label>
			<select
				className="flex w-fit rounded-md border-gray-200 px-3 py-2 pr-9 text-sm shadow-sm focus:border-blue-500 focus:ring-blue-500"
				value={pageSize}
				id="perPage"
				onChange={(e) => {
					handleChange(e);
				}}
			>
				{[10, 20, 30, 40, 50].map((pageSize) => (
					<option key={pageSize} value={pageSize}>
						{pageSize}
					</option>
				))}
			</select>
		</div>
	);
};

export default PerPageSelect;
