import React, { useContext, useEffect, useRef, useState } from "react";
import { BiNotepad } from "react-icons/bi";
import { useAuth0 } from "@auth0/auth0-react";
import { AiOutlineUser } from "react-icons/ai";
import NoteModal from "../../../components/Modals/NoteModal";
import { FaLaptop } from "react-icons/fa";
import { RefreshContext } from "../../../ContextProvider";
import { baseURL } from "../../../helpers/fetchingURL";
import Button from "../../../components/Reusables/Button";

const Notes = (props) => {
	const { user, getAccessTokenSilently } = useAuth0();
	const [notes, setNotes] = useState([]);

	const [hasContentAbove, setHasContentAbove] = useState(false);
	const [hasContentBelow, setHasContentBelow] = useState(false);
	const notesListRef = useRef(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	//addition of note delete modal
	const [showNoteModal, setShowNoteModal] = useState(false);
	const [currentNote, setCurrentNote] = useState();
	const { refreshData } = useContext(RefreshContext);

	useEffect(() => {
		const abortController = new AbortController();

		async function fetchNotes() {
			const accessToken = await getAccessTokenSilently();

			const response = await fetch(
				`${baseURL}/api/notes/${props.responseReference}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					signal: abortController.signal,
				}
			);
			const data = await response.json();
			setNotes(data);
		}

		fetchNotes();

		return () => {
			abortController.abort('Fetch aborted by user');
		};
	}, [props.responseReference, refreshData]);

	function clickNoteModal(curNote) {
		setCurrentNote(curNote);
		setShowNoteModal(true);
	}

	async function submitNote(event) {
		setIsSubmitting(true);
		event.preventDefault();

		const note = event.target.elements.note.value;
		const postBody = {
			name: user.nickname,
			note: note,
			sysGen: 0,
		};

		const accessToken = await getAccessTokenSilently();

		const response = await fetch(
			`${baseURL}/api/notes/${props.responseReference}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(postBody),
			}
		);

		const data = await response.json();

		console.log("notes data", data);

		// Update notes state
		if (notes.length === 0) {
			setNotes([data]);
		} else {
			setNotes([data, ...notes]);
		}
		// Clear textarea
		event.target.elements.note.value = "";
		setIsSubmitting(false);
	}

	// add bottom inset shadow to ul if there is content below it and at the top if there is content above it
	// useEffect(() => {
	// 	function handleScroll() {
	// 		const ul = notesListRef.current;
	// 		if (ul) {
	// 			setHasContentAbove(ul.scrollTop > 0);
	// 			setHasContentBelow(ul.scrollHeight > ul.clientHeight + ul.scrollTop);
	// 		}
	// 	}
	// 	window.addEventListener("scroll", handleScroll);
	// 	return () => window.removeEventListener("scroll", handleScroll);
	// }, [notesListRef]);

	return (
		<>
			<NoteModal
				isOpen={showNoteModal}
				setIsOpen={setShowNoteModal}
				data={currentNote}
				setNotes={setNotes}
			/>
			<div className="flex h-fit flex-col space-y-4 divide-y rounded border bg-white py-4 drop-shadow-sm">
				<div className="flex flex-col space-y-2">
					<h2 className="flex items-center gap-1 px-4 text-lg font-medium">
						<BiNotepad />
						Notes
					</h2>

					{notes.length > 0 && (
						<ul
							className={`space-y-4 overflow-auto max-h-[40vh] px-4
						${hasContentAbove ? "shadow-inner-top" : ""}
						${hasContentBelow ? "shadow-inner-bot" : ""}
						`}
							ref={notesListRef}
						>
							{notes.map((note) => (
								<li className="" key={note.CommunicationID}>
									<p className="mb-1 rounded border p-2 text-sm text-gray-500">
										{note.CommunicationNotes}
									</p>
									<div className="flex">
										<p
											className="flex items-center space-x-1 text-xs text-gray-500"
											title={
												note.SystemGenerated !== 1 ? "Manual" : "Auto-generated"
											}
										>
											{note.SystemGenerated !== 1 ? (
												<AiOutlineUser />
											) : (
												<FaLaptop />
											)}
											<span className="font-medium">{note.PharmacistName}</span>
										</p>
										<p className="ml-2 text-xs text-gray-500">
											<span
												className="underline decoration-dotted"
												title={new Date(
													note.CommunicationDate
												).toLocaleString()}
											>
												{/* Format ISO datetime to users locale */}
												{/* Day of week */}
												{new Date(note.CommunicationDate).toLocaleDateString(
													undefined,
													{
														weekday: "short",
													}
												)}
												{", "}
												{/* Date */}
												{new Date(note.CommunicationDate).toLocaleString(
													undefined,
													{
														day: "numeric",
														month: "short",
														year: "numeric",
													}
												)}
												{/* Time */}{" "}
												{new Date(note.CommunicationDate).toLocaleTimeString(
													undefined,
													{
														hour: "numeric",
														minute: "numeric",
													}
												)}
											</span>
										</p>
										{note.SystemGenerated !== 1 && (
											<button
												className="ml-auto p-0 text-xs text-red-500 underline"
												onClick={() => clickNoteModal(note)}
											>
												Delete Note
											</button>
										)}
									</div>
								</li>
							))}
						</ul>
					)}
				</div>
				<form className="flex flex-col gap-2 px-4 pt-4" onSubmit={submitNote}>
					<h3 className="font-medium leading-none">Add a note</h3>
					<textarea
						className="min-h-[6rem] rounded border-gray-200 text-sm disabled:bg-gray-50"
						name="note"
						id="note"
						cols="30"
						rows="3"
						required
						disabled={isSubmitting}
					></textarea>
					<Button disabled={isSubmitting}>Add</Button>
				</form>
			</div>
		</>
	);
};

export default Notes;
