import { useState, useCallback } from 'react';

import useMountedRef from './useMountedRef';

export default function useMountedState(value) {
	let mountedRef = useMountedRef();
	let [state, setState] = useState(value);

	let setMountedState = useCallback(
		(newState) => {
			if (mountedRef.current) {
				setState(newState);
			}
		},
		[mountedRef],
	);

	return [state, setMountedState];
}
