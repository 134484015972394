import React from "react";

const QuestionnaireQuestions = (props) => {
  let questionText = "";
  if (props.questionRecordId == 1) {
    questionText = `Partner Purchase`;
  } else if (props.questionRecordId == "a_1_b") {
    questionText = `I confirm all of the above to be true
    `;
  } else if (props.questionRecordId == "a_1_b_2_name") {
    questionText = `Partner Purchase - Please provide the details of the end-user so the pharmacist can contact them if necessary:
    `;
  } else if (props.questionRecordId == "a_1_b_2_phone") {
    return;
  } else if (props.questionRecordId == "a_1_b_3") {
    questionText = `Partner Purchase - If Cialis Together is supplied from Precision Pharmacy you will:    `;
  } else if (props.questionRecordId == "a_1_b_4") {
    questionText = `Partner Purchase - I confirm all of the above to be true`;
  } else if (props.questionRecordId == 2) {
    questionText = `Cardiovascular health - Do you have, or have you ever had, a heart condition?`;
  } else if (props.questionRecordId == 3) {
    questionText = `Cardiovascular health - Do you think you may have a heart problem, but not been diagnosed?`;
  } else if (props.questionRecordId == 4) {
    questionText = `Cardiovascular health - Do you get very breathless or feel any pain in the chest with light or moderate activity (e.g. walking briskly for 20 minutes or climbing two flights of stairs)?`;
  } else if (props.questionRecordId == 5) {
    questionText = `Cardiovascular health - Have you had:`;
  } else if (props.questionRecordId == 6) {
    questionText = `Cardiovascular health - Do you have or have ever had any of the following:`;
  } else if (props.questionRecordId == 7) {
    questionText = `Cardiovascular health - Do you get very breathless or feel any pain in the chest with light or moderate activity (e.g. walking briskly for 20 minutes or climbing two flights of stairs)?`;
  } else if (props.questionRecordId == 8) {
    questionText = `Medication and recreational drug use - Do you take any medications (this includes prescription medicines, purchased medicines or recreational drugs, it also includes medicines that you take “when needed”)?`;
  } else if (props.questionRecordId == 9) {
    questionText = `Medication and recreational drug use - Check to see if you take any medicines called nitrates or nitric oxide donors`;
  } else if (props.questionRecordId == 10) {
    questionText = `Medication and recreational drug use - Do you take any of these:    `;
  } else if (props.questionRecordId == "a_10_other") {
    questionText = `I am taking (or have recently stopped taking) a medicine (not listed above) that I’d like to discuss with the pharmacist`;
    
  } else if (props.questionRecordId == 11) {
    questionText = `Conditions - Do you have or have you ever had any of the following`;
  }

  return <p className="text-sm text-gray-500">{questionText}</p>;
};

export default QuestionnaireQuestions;
