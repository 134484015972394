import React from "react";
import Modal from "../Reusables/Modal";
import Button from "../Reusables/Button";
import { baseURL } from "../../helpers/fetchingURL";
import { useAuth0 } from "@auth0/auth0-react";

const NoteModal = ({ data, isOpen, setIsOpen, setNotes, notes }) => {
	const { getAccessTokenSilently } = useAuth0();

	console.log("data", data);

	async function handleSubmit(e) {
		e.preventDefault();
		const postBody = {
			name: data.PharmacistName,
		};

		const accessToken = await getAccessTokenSilently();

		const response = await fetch(
			`${baseURL}/api/notes/${data.CommunicationID}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(postBody),
			}
		);
		//reload notes box

		// Remove note from notes state
		setNotes((prevState) =>
			prevState.filter((note) => note.CommunicationID !== data.CommunicationID)
		);
		setIsOpen(false);
	}

	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<form
				className=" bg-white p-4 rounded shadow w-full "
				onSubmit={handleSubmit}
			>
				<h1 className="text-lg">Are you sure you want to delete this note?</h1>
				<div className="grid grid-cols-2 space-x-2 ">
					<Button color="red">Delete</Button>
					<Button onClick={() => setIsOpen(false)}>Cancel</Button>
				</div>
			</form>
		</Modal>
	);
};

export default NoteModal;
