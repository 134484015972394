import React, { useState } from "react";

const Datepicker = ({ value, setValue, title, placeholder }) => {
  return (
    <input
      type="date"
      value={value}
      onChange={(e) => setValue(e.target.value)}
			className="rounded border border-gray-300  bg-gray-50 px-3 py-2 text-sm"
    />
  );
};

export default Datepicker;
