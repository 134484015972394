import React, { useState } from "react";
import ClickOutside from "../Utility/ClickOutside";
import Button from "./Button";

const Modal = ({ isOpen, setIsOpen, children, className }) => {
  return (
    <>
      {isOpen && (
        <div className={`fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/50 backdrop-blur-sm ${className}`}>
          <ClickOutside action={() => setIsOpen(false)}>
            {children}
          </ClickOutside>
        </div>
      )}
    </>
  );
};

export default Modal;
