import React, { useContext, useEffect, useState } from "react";
import Profile from "../Profile/Profile";
import Button from "../Reusables/Button";
import {
  BiCheckCircle,
  BiClipboard,
  BiPackage,
  BiXCircle,
} from "react-icons/bi";
import ApprovalModal from "../Modals/ApprovalModal";
import { RefreshContext } from "../../ContextProvider";
import ReportModal from "../Modals/ReportModal";

const Header = ({ pharmaStatus, selectedRows }) => {
  const { userRoles } = useContext(RefreshContext);

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [toStatus, setToStatus] = useState("");

  const [hasApproved, setHasApproved] = useState(false);
  const [hasNeedsApproval, setHasNeedsApproval] = useState(false);
  const [hasRejected, setHasRejected] = useState(false);
  const [hasDispatched, setHasDispatched] = useState(false);
  const [hasPassed, setHasPassed] = useState(false);

  useEffect(() => {
    // Reset status flags
    setHasApproved(false);
    setHasNeedsApproval(false);
    setHasRejected(false);
    setHasDispatched(false);
    setHasPassed(false);

    // loop through selected rows and set status flags
    selectedRows.forEach((row) => {
      console.log("row", row);
      if (row.pharmacy_status === "Approved") {
        setHasApproved(true);
      }
      if (row.pharmacy_status === "Needs Approval") {
        setHasNeedsApproval(true);
      }
      if (row.pharmacy_status === "Rejected") {
        setHasRejected(true);
      }
      if (row.pharmacy_status === "Dispatched") {
        setHasDispatched(true);
      }
      if (row.pharmacy_status === "Passed") {
        setHasPassed(true);
      }
    });
  }, [selectedRows]);

  return (
    <>
      <header className="sticky top-0 z-20 flex items-center justify-between border-b bg-white px-4 py-2">
        <Profile />
        <Button
          className="ml-4 mr-auto"
          onClick={() => setShowReportModal(true)}
        >
          <BiClipboard size="20px" />
          <span className="hidden sm:block">Report</span>
        </Button>
        <div
          className={
            "grid h-fit gap-2 md:w-fit " +
            (userRoles.includes("Pharmacist") ? "grid-cols-3" : "")
          }
        >
          <Button
            color="blue"
            disabled={
              selectedRows.length === 0 ||
              !hasApproved ||
              hasDispatched ||
              hasNeedsApproval ||
              hasRejected ||
              hasPassed
            }
            onClick={() => {
              setToStatus("Dispatched");
              setShowApprovalModal(true);
            }}
          >
            <BiPackage size="20px" />
            <span className="hidden sm:block">Dispatch</span>
          </Button>
          {userRoles.includes("Pharmacist") && (
            <>
              <Button
                color="green"
                disabled={
                  selectedRows.length === 0 ||
                  (!hasNeedsApproval && !hasPassed) ||
                  hasApproved ||
                  hasRejected ||
                  hasDispatched
                }
                onClick={() => {
                  setToStatus("Approved");
                  setShowApprovalModal(true);
                }}
              >
                <BiCheckCircle size="20px" />
                <span className="hidden sm:block">Approve</span>
              </Button>
              <Button
                color="red"
                disabled={
                  selectedRows.length === 0 ||
                  hasApproved ||
                  (!hasNeedsApproval && !hasPassed) ||
                  hasDispatched ||
                  hasRejected
                }
                onClick={() => {
                  setToStatus("Rejected");
                  setShowApprovalModal(true);
                }}
              >
                <BiXCircle size="20px" />
                <span className="hidden sm:block">Reject</span>
              </Button>
            </>
          )}
        </div>
      </header>

      <ReportModal isOpen={showReportModal} setIsOpen={setShowReportModal} />

      <ApprovalModal
        isOpen={showApprovalModal}
        setIsOpen={setShowApprovalModal}
        data={selectedRows}
        toStatus={toStatus}
      />
    </>
  );
};

export default Header;
